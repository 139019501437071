
var menu = new Vue({
	el: '#menu',
	data: {
		isActive: false,
	},
	
	computed: {
	
	},
	methods:{
		
	}
});

var carouselPartners = new Vue({
	el: '#carousel-partners',
	data: {
		
	},
	components:{ 
		'carousel': VueCarousel.Carousel,
		'slide': VueCarousel.Slide
	},
	computed: {
	
	},
	methods:{
		
	}
});

var selectCategory = new Vue({
	el: '#select-category',
	data: {
		
	},
	computed: {
	
	},
	methods: {
		onChange(event) {
			window.location.href = event.target.value;
		}
	}
});

const selectAutoForm = document.getElementById('js-form-select-auto');
function submitAuto() {
	selectAutoForm.submit();
}

function resetGetValue(taxonomy) {
	let resetSelect = document.getElementById(taxonomy);
	resetSelect.value = '';
	selectAutoForm.submit();
}

// resize Select
document.querySelectorAll(".resizeselect").forEach(resizeSelect);

function resizeSelect(sel) {
	let tempOption = document.createElement('option');
	tempOption.textContent = sel.selectedOptions[0].textContent;

	let tempSelect = document.createElement('select');
	tempSelect.style.visibility = "hidden";
	tempSelect.style.position = "fixed"
	tempSelect.appendChild(tempOption);

	sel.after(tempSelect);
	sel.style.width = `${+tempSelect.clientWidth}px`;
	tempSelect.remove();
}








function trierLotsMateriaux() {
	const lotSelect = document.getElementById("lotSelect");
	const materiauSelect = document.getElementById("materiauSelect");
	const lotElements = document.querySelectorAll('[data-lot]');
	const materiauElements = document.querySelectorAll('[data-materiaux]');
	const selectedLotItem = document.getElementById("selectedLotItem");
	const selectedLotItemName = document.getElementById("selectedLotItemName");
	const selectedMateriauItem = document.getElementById("selectedMateriauItem");
	const selectedMateriauItemName = document.getElementById("selectedMateriauItemName");
	const removeLotFilterButton = document.getElementById("removeLotFilter");
	const removeMateriauFilterButton = document.getElementById("removeMateriauFilter");


	// Crée un objet pour stocker les matériaux disponibles par lot
	const materiauxParLot = {};

	// Remplit l'objet avec les matériaux disponibles par lot
	lotElements.forEach((lot) => {
		const lotData = lot.getAttribute("data-lot");
		const materiauData = lot.getAttribute("data-materiaux").split(" ");

		materiauxParLot[lotData] = materiauData;
	});

	

	// Gérer le tri des lots
	lotSelect.addEventListener("change", function () {
		const selectedLot = lotSelect.value;
		const selectedLotName = lotSelect.options[lotSelect.selectedIndex].text;
		if (selectedLot === "tous") {
			selectedLotItem.style.display = "none";
			selectedLotItemName.textContent = "";
			removeLotFilterButton.style.display = "none";
		} else {
			selectedLotItem.style.display = "block";
			selectedLotItemName.textContent = selectedLotName;
			removeLotFilterButton.style.display = "inline";
		}

		// Filtrer les options du select des matériaux en fonction du lot sélectionné
		const options = materiauSelect.options;
		for (let i = 0; i < options.length; i++) {
			const materiau = options[i].value;
			const isMateriauDisponible = materiauxParLot[selectedLot].includes(materiau);
			options[i].disabled = !isMateriauDisponible;
		}

		// Réinitialise le sélecteur de matériau à "tous"
		materiauSelect.value = "tous";

		lotElements.forEach((lot) => {
			const lotData = lot.getAttribute("data-lot");
			if (selectedLot === lotData || selectedLot === "tous") {
				lot.style.display = "block";
			} else {
				lot.style.display = "none";
			}
		});
		document.querySelectorAll(".resizeselect").forEach(resizeSelect);
	});

	// Gérer le tri des matériaux
	materiauSelect.addEventListener("change", function () {
		const selectedMateriau = materiauSelect.value;
		const selectedMateriauName = materiauSelect.options[materiauSelect.selectedIndex].text;
		if (selectedMateriau === "tous") {
			selectedMateriauItem.style.display = "none";
			selectedMateriauItemName.textContent = "";
			removeMateriauFilterButton.style.display = "none";
		} else {
			selectedMateriauItem.style.display = "block";
			selectedMateriauItemName.textContent = selectedMateriauName;
			removeMateriauFilterButton.style.display = "inline";
		}

		materiauElements.forEach((materiau) => {
			const materiauData = materiau.getAttribute("data-materiaux");
			const selectedMateriauxArray = selectedMateriau.split(" ");
			if (selectedMateriau === "tous" || selectedMateriauxArray.some(value => materiauData.includes(value))) {
				materiau.style.display = "block";
			} else {
				materiau.style.display = "none";
			}
		});

		
		document.querySelectorAll(".resizeselect").forEach(resizeSelect);
	});

	// Gérer la suppression des filtres de lot et de matériau
	removeLotFilterButton.addEventListener("click", function () {

		for (var i = 0, l = lotSelect.length; i < l; i++) {
			lotSelect[i].selected = lotSelect[i].defaultSelected;
		}
		lotSelect.value = "tous";
		selectedLotItemName.textContent = "";
		removeLotFilterButton.style.display = "none";
		selectedLotItem.style.display = "none";
		

		lotElements.forEach((lot) => {
			lot.style.display = "block";
		});


		const options = materiauSelect.options;
		for (let i = 1; i < options.length; i++) {
			options[i].disabled = false;
		}
		// Réinitialise le sélecteur de matériau à "tous"
		materiauSelect.value = "tous";


		document.querySelectorAll(".resizeselect").forEach(resizeSelect);
	});

	removeMateriauFilterButton.addEventListener("click", function () {
		for (var i = 0, l = materiauSelect.length; i < l; i++) {
			materiauSelect[i].selected = materiauSelect[i].defaultSelected;
		}
		materiauSelect.value = "tous";
		selectedMateriauItemName.textContent = "";
		removeMateriauFilterButton.style.display = "none";
		selectedMateriauItem.style.display = "none";
		materiauElements.forEach((materiau) => {
			materiau.style.display = "block";
		});


		const selectedLot = lotSelect.value;
		if (selectedLot != "tous") {
			lotElements.forEach((lot) => {
				const lotData = lot.getAttribute("data-lot");
				if (selectedLot === lotData || selectedLot === "tous") {
					lot.style.display = "block";
				} else {
					lot.style.display = "none";
				}
			});
		}

		document.querySelectorAll(".resizeselect").forEach(resizeSelect);
	});
}

// Appeler la fonction pour initialiser le tri
trierLotsMateriaux();







// Sélection de tous les éléments avec la classe "js-open-member-popup"
const openButtons = document.querySelectorAll('.js-open-member-popup');

// Ajout d'un gestionnaire d'événement de clic à chaque bouton "js-open-member-popup"
if (openButtons) {
	openButtons.forEach(openButton => {
		openButton.addEventListener('click', () => {
			// Trouver l'élément "js-member-popup" correspondant
			const memberPopup = openButton.closest('.block-equipe-membre').querySelector('.js-member-popup');

			if (memberPopup) {
				// Ajouter la classe "active" à l'élément "js-member-popup"
				memberPopup.classList.add('active');
			}
		});
	});
}

// Sélection de tous les éléments avec la classe "js-close-member-popup"
const closeButtons = document.querySelectorAll('.js-close-member-popup');

// Ajout d'un gestionnaire d'événement de clic à chaque bouton "js-close-member-popup"
if (openButtons) {
	closeButtons.forEach(closeButton => {
		closeButton.addEventListener('click', () => {
			// Trouver l'élément "js-member-popup" correspondant
			const memberPopup = closeButton.closest('.block-equipe-membre').querySelector('.js-member-popup');

			if (memberPopup) {
				// Retirer la classe "active" de l'élément "js-member-popup"
				memberPopup.classList.remove('active');
			}
		});
	});
}



// Sélectionner l'élément js-widget-share-xp
const widgetShareXp = document.querySelector('.js-widget-share-xp');

// Sélectionner l'élément js-widget-share-xp-popup
const widgetShareXpPopup = document.querySelector('.js-widget-share-xp-popup');

// Sélectionner l'élément js-close-xp-popup
const closeXpPopup = document.querySelector('.js-close-xp-popup');

// Ajouter un gestionnaire d'événement de clic à js-widget-share-xp
if (widgetShareXp) {
	widgetShareXp.addEventListener('click', function () {
		// Ajouter la classe 'active' à js-widget-share-xp-popup
		widgetShareXpPopup.classList.add('active');
	});

	// Ajouter un gestionnaire d'événement de clic à js-close-xp-popup
	closeXpPopup.addEventListener('click', function () {
		// Retirer la classe 'active' de js-widget-share-xp-popup
		widgetShareXpPopup.classList.remove('active');
	});
}




